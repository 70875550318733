<template>
  <div>
    <h6 class="question">
      {{ question.libelle }}
    </h6>
    <div
      class="row response"
      v-show="isQuestionLibre"
    >
      <div class="col-12">
        <div class="form-group">
          <input
            class="form-control"
            type="text"
            placeholder="Saisir votre reponse"
            :disabled="disableInput"
            v-model="answer"
          >
        </div>
      </div>
    </div>  

    <div
      class="row response"
      v-show="quetionHasMultipleResponse"
    >
      <div
        class="col"
        v-for="(response, index) in filteredResponsePossible"
        :key="index"
      >
        <div class="form-check">
          <label
            class="form-check-label"
            :for="response.uid"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="response.uid"
              :id="response.uid"
            > {{ response.libelle }}
          </label>
        </div>
      </div>
    </div>

    <div
      class="row response radio-inline"
      v-show="questionHasOneResponse"
    >
      <div
        class="col"
        v-for="(response, index) in filteredResponsePossible"
        :key="index"
      >
        <div class="radio-inline">
          <label
            class="radio"
          >
            <input
              class=""
              type="radio"
              :name="question.uid"
              :id="response.uid"
              :value="response.uid"
              v-model="answer"
            > <span /> {{ response.libelle }}
          </label>
        </div>
      </div>
    </div>
    <!-- <div class="row" v-for="(response, index) in filteredResponsePossible" :key="index">
            
        </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {TRCS} from '../../../constants/app'
export default {
    props: {
        question: {type: Object, required: true}
    },
    watch: {
        answer(){
            if(this.answer !== null) this.$emit('answer', {uid: this.question.uid, libelle: this.question.libelle, ordre: this.question.ordre, answer: this.answer})
        },
      constante: {
        handler(){
          if(this.constante !== null){
            let code = this.question.code
            switch(code){
              case 'temperature': {
                this.answer = this.constante.temperature
                this.disableInput = true
                break
              }
              case 'fr': {
                this.answer = this.constante.fr
                this.disableInput = true
                break
              }
              case 'fc': {
                this.answer = this.constante.fc
                this.disableInput = true
                break
              }
              case 'poid': {
                this.answer = this.constante.poid
                this.disableInput = true
                break
              }
              case 'trc': {
                this.answer = TRCS.find(item => item.value === this.constante.trc).libelle
                this.disableInput = true
                break
              }
            }
          }
        }
      }
    },
    
    data() {
        return {
            review: null,
            answer: null,
            disableInput: false,
            
        }
    },
    computed: {
        ...mapGetters({
            responses: 'sanitaire/responses',
            constante: 'identification/currentConstante'
        }),
        filteredResponsePossible(){
            if(this.question !== null) return [...this.responses].filter(item=> item.question === this.question.uid).sort((a,b) => parseInt(a.ordre) - parseInt(b.ordre))
            return []
        },
        isQuestionLibre(){
            if(this.question !== null) return this.question.libre
            return false
        },
        quetionHasMultipleResponse(){
            if(this.question !== null) return this.question.multiResponse
            return false
        },
        questionHasOneResponse(){
            return !this.isQuestionLibre && !this.questionHasMultipleResponse
        },
    }
}
</script>

<style lang="scss">

.question{
    padding: 20px 5px;
}
.response{
    padding: 10px;
}

</style>